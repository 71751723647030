import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next.js/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/next.js/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/components/Glob/Bottom/styles/bottom.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/components/Glob/Bottom/styles/Form.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Form"] */ "/next.js/src/app/components/Glob/Form/Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next.js/src/app/components/Glob/Navbar/index.tsx");
