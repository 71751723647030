'use client';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { useState } from 'react';
import type { ZodIssue } from 'zod';
import validateForm from './FormValidator';
import { useRouter } from 'next/navigation';

const ErrorMessages = ({ errors }: { errors: string[] }) => {
    if (errors.length === 0) return null;

    const text = errors.join(', ');

    return <div className="text-red-600">{text}</div>;
};

const findErrors = (fieldName: string, errors: ZodIssue[]) => {
    return errors
        .filter((item) => {
            return item.path.includes(fieldName);
        })
        .map((item) => item.message);
};

interface FormProps {
    styles: any;
}

export function Form({ styles }: FormProps) {
    const StrapiBaseUrl = process.env.NEXT_PUBLIC_STRAPI_URL;
    const FormAPIUrl = `${StrapiBaseUrl}/api/forms`;
    const [formData, setFormData] = useState({
        Name: '',
        Country: '',
        Email: '',
        Phone: '',
        Message: '',
        Date: '',
    });

    const [errors, setErrors] = useState<ZodIssue[]>([]);
    const router = useRouter();

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const validationResult = await validateForm(formData);

        if (validationResult.success) {
            try {
                // 获取当前 UTC 时间，并转换为亚洲/上海时区的时间
                const now = new Date();
                const timeZone = 'Asia/Shanghai';
                const zonedDate = toZonedTime(now, timeZone);

                // 创建不同格式的时间值
                const dateForFormSubmission = format(zonedDate, 'yyyy-MM-dd HH:mm:ss'); // 无时区信息
                const dateForEmail = format(zonedDate, 'yyyy-MM-dd HH:mm:ss XXX'); // 带时区信息

                // 用于提交到 /api/forms 的数据，Date 字段不含时区
                const formDataForForm = {
                    ...formData,
                    Date: dateForFormSubmission,
                };

                // 用于发送邮件的表单数据，Date 字段含时区
                const formDataForEmail = {
                    ...formData,
                    Date: dateForEmail,
                };

                const response = await fetch(FormAPIUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ data: formDataForForm }),
                });

                // const data = await response.json();
                // console.log(data);

                if (response.ok) {
                    // alert('Form submitted successfully');
                    setFormData({ Name: '', Country: '', Email: '', Phone: '', Message: '', Date: '' });
                    setErrors([]);
                    router.push('/thanks');

                    // 调用 sendEmail API 发送邮件
                    await fetch('/api/sendEmail', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ formData: formDataForEmail }),
                    });
                } else {
                    alert('Failed to submit form');
                }
            } catch (error) {
                console.error('Submission error:', error);
                alert('Failed to submit form');
            }
        } else {
            setErrors(validationResult.errors);
        }
    };

    const NameErrors = findErrors('Name', errors);
    const CountryErrors = findErrors('Country', errors);
    const EmailErrors = findErrors('Email', errors);
    const PhoneErrors = findErrors('Phone', errors);
    const MessageErrors = findErrors('Message', errors);

    return (
        <form onSubmit={handleSubmit} className="my-10">
            <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                    <input
                        //prettier-ignore
                        className={styles.formInput}
                        type="text"
                        name="Name"
                        placeholder="Name"
                        value={formData.Name}
                        onChange={handleChange}
                    />
                    <ErrorMessages errors={NameErrors} />
                </div>
                <div>
                    <input
                        //prettier-ignore
                        className={styles.formInput}
                        type="text"
                        name="Country"
                        placeholder="Country"
                        value={formData.Country}
                        onChange={handleChange}
                    />
                    <ErrorMessages errors={CountryErrors} />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                    <input
                        //prettier-ignore
                        className={styles.formInput}
                        type="text"
                        name="Email"
                        placeholder="Email"
                        value={formData.Email}
                        onChange={handleChange}
                    />
                    <ErrorMessages errors={EmailErrors} />
                </div>
                <div>
                    <input
                        //prettier-ignore
                        className={styles.formInput}
                        type="text"
                        name="Phone"
                        placeholder="Phone"
                        value={formData.Phone}
                        onChange={handleChange}
                    />
                    <ErrorMessages errors={PhoneErrors} />
                </div>
            </div>

            <div className="mb-6">
                <textarea
                    //prettier-ignore
                    className={styles.formInput}
                    name="Message"
                    placeholder="Please share your size, style of boxes, or other details to get a precise quotation. MOQ is 500pcs."
                    rows={6}
                    value={formData.Message}
                    onChange={handleChange}
                ></textarea>
                <ErrorMessages errors={MessageErrors} />
            </div>

            <button type="submit" className={styles.formSubmit}>
                Submit
            </button>
        </form>
    );
}
