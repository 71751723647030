import React from 'react';
import { NavbarContent, NavbarItem, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu, Button, Link } from '@nextui-org/react';
import { ChevronDown } from './Icons';
import styles from './styles/navbar.module.css';
import { navItems } from './NavbarData';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';

const icons = {
    chevron: <ChevronDown fill="currentColor" size={16} height={16} width={16} />,
};

export default function NavbarDesktop({ locale }: { locale: string }) {
    const pathname = usePathname();
    const t = useTranslations('navbar');

    // 移除 locale 并保留余下路径
    const strippedPathname = '/' + pathname.split('/').slice(2).join('/');

    // console.log('Current Pathname:', pathname);
    // console.log('Stripped Pathname:', strippedPathname);

    return (
        <>
            <NavbarContent className="hidden xl:flex gap-12" justify="center">
                {navItems.map((item) => {
                    const isActive =
                        item.link === '/'
                            ? strippedPathname === item.link // 严格匹配根路径
                            : strippedPathname.startsWith(item.link); // 前缀匹配其他路径

                    // console.log(`Checking item: ${item.label}`);
                    // console.log(`Is Active: ${isActive}`);
                    // console.log(`Link: ${item.link}`);

                    return item.dropdown ? (
                        <Dropdown key={item.key} className="rounded-none">
                            <NavbarItem isActive={isActive}>
                                <DropdownTrigger>
                                    <Button
                                        // prettier-ignore
                                        disableRipple
                                        className="capitalize text-base font-medium p-0 bg-transparent data-[hover=true]:bg-transparent"
                                        endContent={icons.chevron}
                                        radius="sm"
                                        variant="light"
                                    >
                                        {t(item.label)}
                                    </Button>
                                </DropdownTrigger>
                            </NavbarItem>
                            <DropdownMenu
                                aria-label={t(item.label)}
                                className="w-[240px]"
                                itemClasses={{
                                    base: 'gap-4',
                                }}
                                items={item.dropdown}
                            >
                                {(dropdownItem: any) => <DropdownItem key={dropdownItem.key} href={`${dropdownItem.link}`} title={t(dropdownItem.label)} className="py-3" />}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <NavbarItem key={item.key} isActive={isActive}>
                            <Link
                                // prettier-ignore
                                href={`${item.link}`}
                                color="foreground"
                                className="capitalize font-medium"
                            >
                                {t(item.label)}
                            </Link>
                        </NavbarItem>
                    );
                })}
            </NavbarContent>
            <NavbarContent className="hidden xl:flex" justify="end">
                <NavbarItem>
                    <Button
                        // prettier-ignore
                        as={Link}
                        href="/"
                        variant={`bordered`}
                        type="button"
                        radius="sm"
                        className={styles.cat_button}
                    >
                        {t('getQuote')}
                    </Button>
                </NavbarItem>
            </NavbarContent>
        </>
    );
}
